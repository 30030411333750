import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveFavStore, removeFavStore,fetchFavStores } from "./actions";
import { useFavStoreCoupons } from "./useFavStoreCouponsHook";
import CouponsList from "./CouponsList";

const StoreListComponent = ({
  searchText,
  websitelist,
  firstletter,
  favStorelist,
  setSectionClose,
  fetchFavStoresData,
  submitBtn,
  stars,
  isStoreBox,
  setIsStoreBox,
  storeFound,
  coupons,
  seeAll,
  domain,
  copyToClipboard
}) => {
  const [filteredWebsiteList, setFilteredWebsiteList] = useState(websitelist.slice().sort((a, b) => a.store_name.localeCompare(b.store_name)));
  const [addTofavorites, setAddTofavorites] = useState([]);
  const [websiteList, setWebsiteList] = useState(websitelist);
  const [firstLetter, setfirstLetter] = useState(firstletter);
  const [favStoreList] = useState(favStorelist.slice().sort((a, b) => a.store_name.localeCompare(b.store_name)));
  const [favStoreObj, setFavStoreObj] = useState([]);
  const [removeStoreList, setReomveList] = useState([]);
  const dispatch = useDispatch();
  const [favorites, setFavorites] = useState([]);
  const {  fetchFavStoreCouponsData,  fetchstoreCoupons } = useFavStoreCoupons();
  const [couponsList, setCouponsList] = useState(coupons);

  console.log('websiteList ',websiteList)
  console.log('storeFound ',storeFound)
  useEffect(()=>{
    if(fetchFavStoreCouponsData != undefined && fetchFavStoreCouponsData.store_coupons != undefined){  
   console.log(fetchFavStoreCouponsData.store_coupons)
setCouponsList(fetchFavStoreCouponsData.store_coupons);
setIsStoreBox(false)
}
  },[fetchFavStoreCouponsData])

  useEffect(() => {
    setCouponsList(coupons);
  console.log('coupons ',couponsList)

  }, [coupons]);

  useEffect(() => {
    setfirstLetter(firstletter);
  }, [firstletter]);

  const addFavStoreData = useSelector((state) => state.addFavStoreData);
  const RemoveFavStoreData = useSelector((state) => state.RemoveFavStoreData);
  // const fetchFavStoreData = useSelector((state) => state.fetchFavStoreData);
  // useEffect(()=>{

  //   console.log('fetchFavStoreData ',fetchFavStoreData)
  //   if(fetchFavStoreData)
  //   dispatch(fetchFavStores())

  // },[fetchFavStoreData])


  useEffect(() => {
    // Filter out items from websiteList that are present in favStoreList
  if(websiteList != undefined && favStoreList != undefined) {
    // const sortedWebsiteList = websitelist.slice().sort((a, b) => a.store_name.localeCompare(b.store_name));
    // setWebsiteList(sortedWebsiteList);
    //  const filterWebsites = () => {
      const favIds = favStoreList.map((item) => item.store_id);
      const filterWebsites =  websiteList.filter((store) => !favIds.includes(store.store_id));
    // };
console.log('filterWebsites ',filterWebsites)
    setFilteredWebsiteList(filterWebsites.slice().sort((a, b) => a.store_name.localeCompare(b.store_name)));
   
  }
  }, [websiteList, favStoreList]);

// useEffect(()=>{
//   console.log('filteredWebsiteList ',filteredWebsiteList)
//   if(filteredWebsiteList && filteredWebsiteList.length > 0) setfirstLetter(filteredWebsiteList[0].store_name.charAt(0)) 
// },[websiteList])

  useEffect(() => {
    if (searchText === "") {
      const sortedWebsiteList = websitelist.slice().sort((a, b) => a.store_name.localeCompare(b.store_name));
      setWebsiteList(sortedWebsiteList);
      setCouponsList(coupons)
      // setfirstLetter('A')


    } else {
      const filteredWebsitesWithFirstLetter = websitelist
        .filter(
          (website) =>
            website.store_name.toLowerCase().charAt(0) ===
            firstLetter.toLowerCase()
        )
        .sort((a, b) => a.store_name.localeCompare(b.store_name));

      const sortedWebsites = filteredWebsitesWithFirstLetter
        .filter((website) =>
          website.store_name.toLowerCase().includes(searchText.toLowerCase())
        )
        .sort((a, b) => a.store_name.localeCompare(b.store_name));

      setWebsiteList(
        filteredWebsitesWithFirstLetter.length > 0 || sortedWebsites.length > 0
          ? filteredWebsitesWithFirstLetter
          : []
      );
    }
  }, [searchText, firstLetter, websitelist]);
  const removeFavListFunction = (favStore, index) => {
    let removeArray = removeStoreList;
    removeArray.push(JSON.stringify({ store_id: favStore.store_id }));
    // removeArray=JSON.stringify(removeArray)
    setReomveList(removeArray);

    if(stars == 'none')
    getFavStoreCoupons(favStore)

    // console.log(favStoreList);
    // const updatedList = favStoreList.map((item, i) =>
    //   i === index ? { ...item, add: !item.add } : item
    // );
    // console.log("updatedList ", updatedList);
    // setFavStoreObj(updatedList);
    // // setFavStoreList(updatedList);

    // // let storedFavStores = JSON.parse(localStorage.getItem('favStores')) || [];

    // // Combine the existing favorite stores with the new stores
    // console.log(favStoreObj);
    // setFavStoreObj(updatedList);
    // console.log(favStoreObj);
  };

  const toggleFavorite = (storeId, setFavorites) => {
    setFavorites((prevFavorites) =>
      prevFavorites.includes(storeId)
        ? prevFavorites.filter((id) => id !== storeId)
        : [...prevFavorites, storeId]
    );
  };
  const favListFunction = (store, index) => {
    toggleFavorite(store.store_id, setAddTofavorites);
    let obj = {
      store_id: store.store_id,
      store_name: store.store_name,
      store_url: store.store_url,
    };
    console.log(obj);
    let objArray = [...favStoreObj, obj];
    console.log("objArray ", objArray);
    setFavStoreObj(objArray);
    if(stars == 'none')
    getFavStoreCoupons(store)

    // const updatedList = websiteList.map((item, i) =>
    //   i === index ? { ...item, add: !item.add } : item
    // );
    // setWebsiteList(updatedList);

    //     setAddFavStore(addFavStore == index ?null :index)
    //     const storeIndex= favStoreObj.findIndex(store=>store.store.store_name.toLowerCase() == website.store_name.toLowerCase())
    //     console.log('storeIndex ',storeIndex)
    //     if(storeIndex != -1){
    //       console.log(favStoreObj[storeIndex].add)
    //         const updatedStore = { ...favStoreObj[storeIndex], add: !favStoreObj[storeIndex].add };
    //         console.log('updatedStore ',updatedStore)

    //         let updateArray = [...favStoreObj];
    //         updateArray[storeIndex]= updatedStore;
    //         // localStorage.setItem('favList', JSON.stringify(updateArray));

    //         setFavStoreObj(updateArray)
    //         console.log(favStoreObj)
    //     }
    //     else{
    //     let newObj = new Object({
    //         store:website,
    //         add: true
    //     })
    // const favWebArray= [...favStoreObj];
    // favWebArray.push(newObj);
    // console.log(favWebArray)
    // // localStorage.setItem('favList', JSON.stringify(favWebArray));
    // setFavStoreObj(favWebArray)

    // }
  };
  const addSelection = () => {
    console.log(favStoreObj);
    console.log("removeStoreList ", removeStoreList);
    if (removeStoreList.length > 0) {
      removeStoreList.map((store) => {
        return dispatch(removeFavStore(store));
      });
    }

    if (favStoreObj.length > 0)
      dispatch(saveFavStore({ store_data: favStoreObj }));


    
    setSectionClose(true);

    // setGetFavStores(true)
  };

  const getFavStoreCoupons = (store)=>{
    fetchstoreCoupons(store.store_id);
    // setShowList(false)
  }





  
  useEffect(() => {
    if (favStoreList) {
      favStoreList.map((store) => {
        return toggleFavorite(store.store_id, setFavorites);
      });
      console.log(favorites);
    }
  }, []);

  return (
    <>
   {  stars != 'none' || isStoreBox ?
      <div
        className="favListBox"
        style={{
          overflow: "hidden",
          overflowY: "scroll",
          height: "400px",
          paddingRight:'10px'
          // display : isStoreBox ? 'block' : 'none'
        }}
      >
        {  searchText =='' &&favStoreList != undefined && favStoreList && favStoreList.length > 0 ? (
          <>
            <h3
              style={{
                borderBottom: " 1px dotted gray",
                padding: "14px 14px 0px 0px",
                fontSize:'15px'
              }}
            >
              Favorites
            </h3>
            <ul className="favStores" >
              { favStoreList.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    removeFavListFunction(item, index);
                    toggleFavorite(item.store_id, setFavorites);
                  
                  }}
                >
                  {item.store_name}{" "}
                  {/* <span
                    style={{
                      color:addTofavorites.includes(store.store_id)
                        ? " #ff41ff"
                        : "",
                          display:stars ? stars :''
                    }}
                  >
                    &#9734;{" "}
                  </span> */}

<span
                        className=" "
                        // onClick={() => {
                        //   addRemoveFromFavourite(1, item.product_url, index);
                        // }}
                        style={{
                          display: favorites.includes(item.store_id) ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-solid fa-star"
                          style={{
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>

                      <span
                        className=""
                      
                        style={{
                          display: !favorites.includes(item.store_id) ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-regular fa-star"
                          style={{
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <></>
        )}
        {filteredWebsiteList != undefined && filteredWebsiteList && filteredWebsiteList.length > 0 ? (
          <>
            <h3
              style={{
                textTransform: "uppercase",
                borderBottom: " 1px dotted gray",
                padding: "14px 14px 0px 0px",
                fontSize:'15px'
              }}
            >
              {/* {firstLetter} */}
              {filteredWebsiteList[0].store_name.charAt(0)}
            </h3>
            <ul className="storeList">
              {filteredWebsiteList.map((store, index) => (
                <li
                  key={index}
                  onClick={() => {
                    favListFunction(store);
                  }}
                >
                  {store.store_name}{" "}
                  {/* <span
                    style={{
                      color: addTofavorites.includes(store.store_id)
                        ? " #ff41ff"
                        : "",
                        display:stars ? stars :''
                    }}
                  >
                    {" "}
                    &#9734;{" "}
                  </span> */}

<span
                        className=" "
                        // onClick={() => {
                        //   addRemoveFromFavourite(1, item.product_url, index);
                        // }}
                        style={{
                          display:addTofavorites.includes(store.store_id) ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-solid fa-star"
                          style={{
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>

                      <span
                        className=""
                      
                        style={{
                          display: !addTofavorites.includes(store.store_id) ? "block" : "none",
                        }}
                      >
                        <i
                          className="fa-regular fa-star"
                          style={{
                            color: "#9D4EDD",
                          }}
                        ></i>
                      </span>
                </li>
              ))}
            </ul>
          </>
        ) : (
       
          <>
             {storeFound == false ?<p style={{   
            textAlign: 'center',
            transform: 'translate(-3%, 100%)'}}>No result found</p> :''}
            </>
        )}
      </div>
      :
      <>
      {
        stars == 'none' ?
        <CouponsList
        coupons={couponsList}
        seeAll={seeAll}
        domain={domain}
        copyToClipboard={copyToClipboard}
      />:
      ''
      }
      </>
   
      
      }
      <button
        onClick={addSelection}
        className="saveFavSelection"
        style={{ top: "30px", width: "100%",display: submitBtn ? submitBtn:'' }}
      >
        Submit Selection
      </button>
    </>
  );
};

export default StoreListComponent;
