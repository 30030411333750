import React from 'react';
import Skeleton from 'react-loading-skeleton';
// import ProductListContainer from './ProductListContainer';
// import CreateNewList from './createNewList';

const SingleProductDisplay = ({
  singleProduct,
  firstWord,
 noPrice,
}) => {
  return (!singleProduct && singleProduct.product_title === "") || singleProduct.imgSrc === "" ? (
    <div>
      {Array.from({ length: 1 }, (_, index) => (
        <div className="items_wrapper" key={index}>
          <div className="cards-wrapper">
            <div className="card" style={{ width: "100%" }}>
              <Skeleton height={90} width={90} src={""} alt="" className="images_size" />
              <div>
                <Skeleton height={10} width={150} style={{ marginTop: "40px" }} />
                <Skeleton height={10} width={150} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="addContainer" style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
      <ul style={{ display: "flex", width: "100%", background: "", borderRadius: "10px 0 0 10px" }}>
        <li style={{ display: "flex", flex: 1, cursor: "pointer", marginBottom: '35px' }}>
          <img
            src={singleProduct.product_image}
            alt={singleProduct.product_title}
            className="images-size"
            style={{ borderRadius: "10px", minWidth: "100px", background: "white" }}
          />
          <div className="productDetail" style={{ justifyContent: "flexStart", backgroundColor: "transparent" }}>
            <div>
              <p className="title" style={{ textTransform: "uppercase" }}>{firstWord}</p>
            </div>
            <div>
              <p className="title" style={{ whiteSpace: "normal", textTransform: "uppercase", fontSize: "11px" }}>
                {singleProduct.product_title}
              </p>
            </div>
         {
          noPrice ? <></> :
          <div className="price" style={{ lineHeight: "28px",color:'rgba(254, 138, 67, 1)', fontSize: "11px" }}>
          <span>{ singleProduct.product_price}</span>
        </div>
         }
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SingleProductDisplay;
