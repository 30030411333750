export const buildUrl = (uuid, url, platform,store_id,event='plugin_close', source = 'PLUGIN') => {
    console.log(store_id,uuid,url,platform)

    const baseUrl = 'https://api.deals2buy.in/openUrl.php';
    const params = new URLSearchParams({ uuid,url,source,event,store_id });
    return `${baseUrl}?${params.toString()}`;
  };

  export  function getStoreName(url) {
    let storeUrl = !url ?'':url
    console.log('storeUrl ',storeUrl)
    // Use a regex to extract the domain name without 'www' and '.com'
    const regex = /https?:\/\/(?:www\.)?([^\.]+)\.(com|in)/;
    const match = storeUrl.match(regex);

    // If there's a match, return the store name
    if (match && match[1]) {
        return match[1];
    } else {
        return null;
    }
}


export const getDealsUrl = (url, storeId,domain) => {
    console.log('domain ',domain)
    handleRedirect(url, storeId, true,domain);
  };

export const handleRedirect = (url,storeId,redirectTab,domain) => {
    let HTMLencodedUrl =encodeURIComponent(url)
    if(storeId !=''){
        HTMLencodedUrl = `${HTMLencodedUrl}&${storeId}`;
    }
    let finalUrl = `https://api.deals2buy.in/redirect.php?url=${HTMLencodedUrl}&store_id=${storeId}`
    console.log('dealUrl ',finalUrl)
    // const link = document.createElement('a');
    // link.href = finalUrl; // Replace with your desired URL
    // link.target = '_blank'; // Open in the same tab; use '_blank' to open in a new tab
    // link.click();

    const parentWindow = window.parent;
console.log(domain)

parentWindow.postMessage({ action: 'redirectToStore',storeUrl:finalUrl,redirectTab:redirectTab }, domain);
  };

 

