import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ProductUpdateComponent.css";
import {
  useFetchList,
  useAddToList,
  useSaveToWishList,
  useUpdateNotification,
  useDeleteProduct,
} from "./SaveWishListHook";
import {
  useSimilarItems,
  useEditSettings,
  usePriceEvolution,
  useProductReview,
} from "./ThreeDotsApiCallsHook";
import WishListCard from "./wishListCard";
import { ListComponent } from "./listComponent";
import EditList from "./EditListComponent";
import SingleProductDisplay from "./singleProductComponent";
import { toBeDisabled } from "@testing-library/jest-dom/matchers";
import useGetWishList from "./getWishListHook";
import Product from "./Product";

const ProductUpdateComponent = ({
  setThreeDotsClicked,
  product,
  threeDotsEvent,
  threeDotsClicked,
  addToListIdFunction,
  setMuteNotifcationFunction,
  domain
}) => {
  // console.log("productData ", product);
  const [productData, setPoductData] = useState(product);
  const { wishListData } =  useGetWishList();

  useEffect(()=>{
if(wishListData != undefined && wishListData.data != undefined && wishListData.data.length > 0){
  let data = wishListData.data.filter(data=> data.product_url == product.product_url);
  data = data[0]
  setPoductData(data)
  console.log('productData ',productData)
}
  },[wishListData,product])

  let productUpdateArr = [
    { icon: "far fa-star", list_name: "Add to Favorites" ,toBeDisabled:false },
    { icon: "fa-solid fa-check", list_name: "Add to List",toBeDisabled:false },
    { icon: "fa fa-share", list_name: "Share Product",toBeDisabled:false },
    { icon: "fa fa-toolbox", list_name: "Mark as Fulfilled",toBeDisabled:false },
    { icon: "fa fa-gear", list_name: "Edit Settings",toBeDisabled:false },
    { icon: "fa fa-bell", list_name: "Mute Notifications",toBeDisabled:false },
    { icon: "fa fa-money", list_name: "Price Evolution",toBeDisabled:false },
    { icon: "fa-solid fa-cart-shopping", list_name: "Similar Items",toBeDisabled:false },
    { icon: "fa-solid fa-message", list_name: "Reviews" ,toBeDisabled:false},
    { icon: "fa fa-trash", list_name: "Delete Product" ,toBeDisabled:false},
  ];

  let shareOptions = localStorage.getItem("socialShareData")
    ? JSON.parse(localStorage.getItem("socialShareData"))
    : [];

  const dummyData = [
    {
      imageUrl: "https://via.placeholder.com/50",
      storeName: "Visit the Lavie Store",
      productName: "Lavie Women's Betula Tote Bag | Ladies Purse Handbag",
      currentPrice: "₹1,049.00",
      originalPrice: "₹3,190.00",
      onSale: true,
      color: "Black",
      notifyOptions: [
        "Please choose",
        "When price drops",
        "When back in stock",
      ],
      listOptions: ["Fulfilled", "Wishlist", "Cart"],
    },
  ];

  const [notifcationArr, setNotificationArr] = useState([]);

  const dispatch = useDispatch();
  const [favouriteListId, setfavouriteListId] = useState();
  const [fullFilledId, setfullFilledId] = useState();
  const [selectList, setSelectList] = useState(false);
  const [showShareProducts, setShowProduct] = useState(false);
  const [editSetting, setEditSetting] = useState(false);
  const [similarItems, setSimilarItems] = useState(false);
  const [isReviews, setIsReviews] = useState(false);
  // const [shareProducts, setShareProducts] = useState(false);
  const [priceEvolution, setPriceEvolution] = useState(false);
  const [notifyWhen, setNotifyWhen] = useState(false);
  const [addLists, setAddLists] = useState(false);
  const [productUrl, setProductUrl] = useState();
  const [selectedListIds, setSelectedListIds] = useState([]);
  const [muteNotification, setMuteNotifcation] = useState(
    productData.is_mute_notification
  );
  const { fetchListData } = useFetchList();
  console.log("notifyWhen ", notifyWhen);

  const { similarItemsData, similarItemsDataFromServer } = useSimilarItems();
  const { addToListData, addToFavOrFullFilledOrList } = useAddToList();
  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();
  const { addUpdateNotificationData, updateNotifcation } =  useUpdateNotification();
  const { deleteProductData, deleteProductfromServer } = useDeleteProduct();
  const { editSettingsData ,getNotificationsOptions} = useEditSettings({type:'PRODUCT'});
  const { priceEvolutionData, priceEvolutionDataFromServer } = usePriceEvolution();
 const  { productReviewsData,   productReviewDataFunction  } = useProductReview();
 const [reviewsData,setReviewData] = useState('');
  const [selectedListNames, setSelectedListNames] = useState('Please choose');
 

  useEffect(() => {
    setProductUrl(productData.product_url);
    const updatedArray = productUpdateArr.map((item) => {
      switch (item.list_name) {
        case "Add to Favorites":
          return {
            ...item,
            icon: productData.is_favourite ? "fa fa-star" : "far fa-star",
            list_name: productData.is_favourite
              ? "Remove from Favorites"
              : "Add to Favorites",
          };
        case "Mark as Fulfilled":
          return {
            ...item,
            list_name: productData.is_fulfilled
              ? "Unmark as Fulfilled"
              : "Mark as Fulfilled",
          };
        case "Mute Notifications":
          return {
            ...item,
            icon: muteNotification ? "fa-solid fa-bell-slash" : "fa fa-bell",
            list_name: muteNotification
              ? "Unmute Notifications"
              : "Mute Notifications",
          };

          case "Similar Items":
            return {
              ...item,
              toBeDisabled: similarItemsData != undefined && similarItemsData.similar_deals != undefined &&  similarItemsData.similar_deals.length >0
                ? false
                : true,
            };

            case "Reviews":
              return {
                ...item,
                toBeDisabled: productReviewsData != undefined && productReviewsData.data != undefined &&  productReviewsData.data.length >0
                  ? false
                  : true,
              };

              case "Share Product":
                return {
                  ...item,
                  toBeDisabled: shareOptions != undefined && shareOptions.length >0
                    ? false
                    : true,
                };
        default:
          return item;
      }
    });
    setNotificationArr(updatedArray);

    console.log("updatedArray", updatedArray);
  }, [productData, muteNotification]);

  useEffect(() => {
    const favouriteList =  fetchListData && fetchListData.data.find(
      (list) => list.list_name === "Favourite"
    );
    setfavouriteListId(favouriteList ? favouriteList.list_id : null);
  
    const fullfilledList = fetchListData && fetchListData.data.find(
      (list) => list.list_name === "Fulfilled"
    );
    setfullFilledId(fullfilledList ? fullfilledList.list_id : null);
  
    toChooseSelectededIds();
  }, [fetchListData, productData,similarItemsData]);
  

  function toChooseSelectededIds (){
    const productListIds = productData.list_id !='' ? productData.list_id.split(',').map(id => parseInt(id, 10)):[];
  
    // Retrieve existing list from local storage and parse it
    const existingList = new Set(JSON.parse(localStorage.getItem("selectedLists")) || []);
  
    // Add only those product list ids that are not already present in existing list and are not null or undefined
    productListIds.forEach(id => {
      if (id !== null && id !== undefined) {
        console.log('existingList',id)
        existingList.add(id);
        return  [...existingList];
      }
    });
    console.log('existingList',productListIds)
    // Convert set back to array to maintain order (if needed)
    const newList =  [...productListIds];
  console.log('newList',newList)
    // Save the filtered list back to local storage
    localStorage.setItem("selectedLists", JSON.stringify(newList));
  
    const checkedListData = fetchListData.data
      .filter((list) => newList.includes(list.list_id))
      .map((list) => list.list_name);
  
    console.log("checkedListData ", checkedListData);
    setSelectedListNames(checkedListData ? checkedListData : 'Please choose');
  }
  useEffect(()=>{

  if(productData != undefined){ 
    similarItemsDataFromServer({
      product_url: productData.product_url,
      product_title: productData.product_title,
    });

    toChooseSelectededIds();
  }

  if(productData != undefined){
    productReviewDataFunction({product_url:productData.product_url})
  }
  },[productData])
 
  useEffect(()=>{
    console.log('notificationId ',productData.notification_option_id)
    if(productData.notification_option_id )
    localStorage.setItem("notificationId",JSON.stringify(productData.notification_option_id))
  // else
  // localStorage.setItem("notificationId",JSON.stringify('Please choose'))

   
  const checkedNotication = editSettingsData.notification_options != undefined &&  editSettingsData.notification_options
    .filter(
      (list) =>
        productData.notification_option_id != undefined && productData.notification_option_id == list.notification_id
    )
    .map((list) => list.notification_title);
  console.log("checkedNotication ", checkedNotication);
  
  setCheckedNotificationOption(checkedNotication ? checkedNotication.toString() : "Please choose");
   
  },[productData])
  
  useEffect(() => {
    console.log("productData.is_mute_notification ", productData);
    console.log(
      "productData.is_mute_notification ",
      productData.is_mute_notification
    );
    if (productData.is_mute_notification) {
      setMuteNotifcation(productData.is_mute_notification);
    }


      getNotificationsOptions({ type: 'PRODUCT'});
  
 
  }, [productData]);

  const productUpdate = (index) => {
    let url = productData.product_url;

    // Function to join array elements with commas
    // const joinListIds = (ids) => ids ? ids.join(','):'';

    // Save to fav or fullfilled list
    if (index === 0 || index === 3) {
      let updatedListIds;
    
      if (index === 0) {
        updatedListIds = favouriteListId;
      } else if (index === 3) {
        updatedListIds = fullFilledId;
      }
    
      // Ensure updatedListIds is an array if it's a number
      if (typeof updatedListIds === 'number') {
        updatedListIds = [updatedListIds];
      }
    
      // Fetch IDs from localStorage if present
      const localStorageIdsString = localStorage.getItem('selectedLists');
      const localStorageIds = localStorageIdsString ? JSON.parse(localStorageIdsString) : [];
    
      // Convert localStorageIds to a Set to ensure uniqueness
      const existingList = new Set(localStorageIds);
    
      // Check if updatedListIds are already in localStorageIds
      let shouldUpdate = false;
      updatedListIds.forEach(id => {
        if (id !== null && !existingList.has(id)) {
          shouldUpdate = true;
          existingList.add(id);
        } else if (existingList.has(id)) {
          shouldUpdate = true;
          existingList.delete(id); // Remove if already exists to toggle the boolean
        }
      });
    
      // Convert Set back to an array and filter out null values
      const updatedIdsArray = Array.from(existingList).filter(id => id !== null);
    
      // Save updatedIdsArray back to localStorage
      localStorage.setItem('selectedLists', JSON.stringify(updatedIdsArray));
    
      console.log("Updated List IDs:", updatedIdsArray);
    
      // Ensure updatedIdsArray is an array before passing to addToListFunction
      addToListFunction(updatedIdsArray);
    }
    

    // add to list
    if (index === 1) {
      setAddLists(true);
      setSelectList(true);
      setPriceEvolution(false);
      setSimilarItems(false);
      setEditSetting(false);
      setShowProduct(false);
    }
    // share products
    if (index === 2) {
      console.log("clicked 2");
      setSelectList(true);
      setShowProduct(true);
      setPriceEvolution(false);
      setSimilarItems(false);
      setEditSetting(false);
      setNotifyWhen(false);
    }

    // editSettings
    if (index == 4) {
      setAddLists(false);
      setSelectList(true);
      setEditSetting(true);
    }
    // Toggle mute notification
    if (index === 5) {
      setMuteNotifcationFunction(muteNotification, "", productUrl);
      // setThreeDotsClicked(false)
      setSelectList(true);
    }

    //  price evolution
    if (index === 6) {
      setSelectList(true);
      setPriceEvolution(true);
      setSimilarItems(false);
      setEditSetting(false);
      setShowProduct(false);
      setNotifyWhen(false);
      priceEvolutionDataFromServer({
        product_uuid: productData.deal_uuid,
      });
    }
    // getsimlar items
    if (index == 7) {
      setSelectList(true);
      setPriceEvolution(false);
      setSimilarItems(true);
      setEditSetting(false);
      setShowProduct(false);
      setNotifyWhen(false);
      console.log("productData id ", productData.id);
  
      // setThreeDotsClicked(setThreeDotsClicked)
    }
    // reviews
    if (index == 8) {
      setSelectList(true);
      setIsReviews(true);
      
    }
    // Delete product
    if (index == 9) {
      console.log("productData id ", productData.id);
      deleteProductfromServer({
        wishlist_id: productData.id,
      });
      // setThreeDotsClicked(setThreeDotsClicked);
      threeDotsEvent()
    }
  };

  const [checkedNotificationOption, setCheckedNotificationOption] =
    useState("");
  const onConfirmation = () => {
    const listIds = localStorage.getItem('selectedLists') ?  JSON.parse(localStorage.getItem('selectedLists')).join(',') :[];
    // Filter and map to get the list names
    const checkedListData = fetchListData.data
      .filter(
        (list) =>
          listIds != undefined && listIds.includes(list.list_id.toString())
      )
      .map((list) => list.list_name);
    console.log("checkedListData ", checkedListData);
    setSelectedListNames(checkedListData ? checkedListData :'Please choose');

    const notificationId =  localStorage.getItem("notificationId") ?JSON.parse(localStorage.getItem("notificationId")):'';
    const checkedNotication = editSettingsData.notification_options
      .filter(
        (list) =>
          notificationId != undefined && notificationId == list.notification_id
      )
      .map((list) => list.notification_title);
    console.log("checkedListData ", checkedNotication);
    setCheckedNotificationOption(checkedNotication ? checkedNotication : "");
  };

  const addToListFunction = (listIds) => {
    // const listIds =listIds.split(',')
    addToListIdFunction(listIds, productUrl);
    setSelectList(false);
  };

  const addToNotifyFunction = () => {
    setSelectList(true);
    setShowProduct(false);
    setEditSetting(false);
    setNotifyWhen(true);
  };

  const addToList = (listIds) => {
    setSelectList(true);
    setShowProduct(false);
    setEditSetting(false);
    setNotifyWhen(false);
  };

  const lastChangesSaving = () => {
    console.log("lastChangesSaving ");
    const listIds = localStorage.getItem('selectedLists') ?  JSON.parse(localStorage.getItem('selectedLists')).join(',') :[];
    const notificationId =  localStorage.getItem("notificationId") ?JSON.parse(localStorage.getItem("notificationId")):'';
    console.log("notificationId ", notificationId);
    console.log("listIds ", listIds);
    addToListIdFunction(listIds, productUrl);
    setMuteNotifcationFunction(muteNotification, notificationId, productUrl);
    // localStorage.removeItem("listIds");
    // setEditSetting(true)
    // setSelectList(true)
  };

  const reviewData = [
    { rating: 4, text: "Worth the money. ordered for my mom, she liked it" },
    { rating: 5, text: "Love the product, perfect" },
    { rating: 5, text: "The handbag is very compact & useful for day use. The quality is also good. Value for money" },
    { rating: 4, text: "It's value for money and is durable" },
    { rating: 5, text: "Colour is quite attractive. Smooth finishing" },
    { rating: 3, text: "Good quality" },
  ];

  return (
    <>
      {!selectList ? (
        <>
          <div
            className="filterOverlay"
            style={{ display: "flex", height: "100vh" }}
          >
            <div
              className="noticationPopup"
              style={{
                padding: "0px 20px",
                height: "592px",
                position: "static",
                maxHeight: "100%",
                // marginBottom: "44%",
              }}
            >
              <div onClick={() => threeDotsEvent("Some Data")}>
                <i
                  class="fa fa-close"
                  aria-hidden="true"
                  style={{
                    fontSize: "25px",
                    float: "right",
                    position: "relative",
                    top: "12px",
                    right: "0",
                    fontSize: "20px",
                    fontWeight: "200",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <>
                <SingleProductDisplay
                  singleProduct={productData}
                  noPrice={true}
                />
                <div
                  style={{
                    // padding: "20px",
                    background: " #FBFBF8",
                    borderRadius: "20px 20px 0px 0px",
                    width: "100%",
                  }}
                >
                  <ul className="recentlyViewed">
                    {productData != undefined &&
                      productData.length > 0 &&
                      productData.map((product, index) => (
                        <li key={index}>
                          <img
                            src={product.product_image}
                            alt={product.product_title}
                            class="images-size"
                            // onClick={() => getProductUrl(product.product_url)}
                          />
                          <div
                            className="productDetail"
                            // onClick={() => getProductUrl(product.product_url)}
                            style={{ background: "white" }}
                          >
                            <div class="price">
                              <span>{product.product_price}</span>{" "}
                              <span class="line">0</span>
                            </div>
                            <div>
                              <p class="title">{product.product_title}</p>
                            </div>
                          </div>
                          <>
                            <span
                              className="discount saveDeal"
                              onClick={() => {
                                // saveDeal(product, index);
                              }}
                              style={{
                                // display: saveItem[index] ? "block" : "none",
                                marginLeft: "287px",
                                top: "56px",
                              }}
                            >
                              <i
                                className="fa-solid fa-heart"
                                style={{
                                  width: "57px",
                                  position: "absolute",
                                  marginTop: "-49px",
                                  left: "71%",
                                  color: "#9D4EDD",
                                  fontSize: "18px",
                                }}
                              ></i>
                            </span>
                            <span
                              className="discount saveDeal"
                              //   onClick={() => {
                              //     saveDeal(product, index);
                              //   }}
                              style={{
                                // display: !saveItem[index] ? "block" : "none",
                                marginLeft: "287px",
                                top: "56px",
                              }}
                            >
                              <i
                                className="fa-regular fa-heart"
                                style={{
                                  width: "57px",
                                  position: "absolute",
                                  marginTop: "-49px",
                                  left: "71%",
                                  color: "#9D4EDD",
                                  fontSize: "18px",
                                }}
                              ></i>
                            </span>
                          </>
                        </li>
                      ))}
                  </ul>
                </div>
                <ListComponent
                  productUpdateArr={notifcationArr}
                  productUpdate={productUpdate}
                />
              </>
            </div>
          </div>
        </>
      ) : (
        <>
          {showShareProducts ? (
            <EditList
            domain={domain}
              productsList={shareOptions}
              editPen={"none"}
              setSelectList={setSelectList}
              Headding={"Add To List"}
              // btnText1={'Confirm'}
              // btnText2={'Add new list'}
              addToListFunction={addToListFunction}
              threeDotsEvent={threeDotsEvent}
              showShareProducts={showShareProducts}
              gap={"32px"}
            />
          ) : (
            <>
              {editSetting ? (
                <>
                  <div
                    className="filterOverlay"
                    style={{ display: "flex", height: "100vh" }}
                  >
                    <EditList
                    domain={domain}
                      productsList={[productData]}
                      addToListFunction={addToListFunction}
                      editPen={"none"}
                      setSelectList={setSelectList}
                      Headding={"Add To List"}
                      btnText1={"Confirm"}
                      addToList={addToList}
                      // fetchListData={fetchListData}
                      threeDotsEvent={threeDotsEvent}
                      editSetting={editSetting}
                      setEditSetting={setEditSetting}
                      addToNotifyFunction={addToNotifyFunction}
                      lastChangesSaving={lastChangesSaving}
                      selectedListNames={selectedListNames}
                      setSelectedListNames={setSelectedListNames}
                      checkedNotificationOption={checkedNotificationOption}
                    />
                  </div>
                </>
              ) : (
                <>
                  {similarItems ? (
                    <>
                      <EditList
                      domain={domain}
                        // productsList={[
                        //   {
                        //     id: 98,
                        //     user_id: 293,
                        //     uuid_type: "deal",
                        //     uuid: "TKjtnLuVKevj",
                        //     product_url:
                        //       "https://www.amazon.in/dp/B087ZLV39L?tag=deals2buy0b-21",
                        //     product_title:
                        //       "Lifelong 1200 Watts Mixer Grinder Power Plus With 3 Jars (Liquidizing, Wet Grinding And Chutney Jar), Stainless Steel Blades, 1 Year Manufacturer'S Warranty (Black, Llmg210)",
                        //     product_category: "",
                        //     product_image:
                        //       "https://lannister.deals2buy.in/assets/images/IMG_Lifelong_Watts_Mixer_Grinder_20240621_16_54_03_amazon.png",
                        //     product_price: "4499",
                        //     is_purchased: 0,
                        //     wishlist_status: 1,
                        //     list_id: "",
                        //     created_at: "2024-06-21 17:09:06",
                        //     is_favourite: 0,
                        //     is_fulfilled: 0,
                        //     is_mute_notification: 1,
                        //     notification_option_id: 1,
                        //     is_review: 1,
                        //   },
                        //   {
                        //     id: 98,
                        //     user_id: 293,
                        //     uuid_type: "deal",
                        //     uuid: "TKjtnLuVKevj",
                        //     product_url:
                        //       "https://www.amazon.in/dp/B087ZLV39L?tag=deals2buy0b-21",
                        //     product_title:
                        //       "Lifelong 1200 Watts Mixer Grinder Power Plus With 3 Jars (Liquidizing, Wet Grinding And Chutney Jar), Stainless Steel Blades, 1 Year Manufacturer'S Warranty (Black, Llmg210)",
                        //     product_category: "",
                        //     product_image:
                        //       "https://lannister.deals2buy.in/assets/images/IMG_Lifelong_Watts_Mixer_Grinder_20240621_16_54_03_amazon.png",
                        //     product_price: "4499",
                        //     is_purchased: 0,
                        //     wishlist_status: 1,
                        //     list_id: "",
                        //     created_at: "2024-06-21 17:09:06",
                        //     is_favourite: 0,
                        //     is_fulfilled: 0,
                        //     is_mute_notification: 1,
                        //     notification_option_id: 1,
                        //     is_review: 1,
                        //   },
                        //   {
                        //     id: 98,
                        //     user_id: 293,
                        //     uuid_type: "deal",
                        //     uuid: "TKjtnLuVKevj",
                        //     product_url:
                        //       "https://www.amazon.in/dp/B087ZLV39L?tag=deals2buy0b-21",
                        //     product_title:
                        //       "Lifelong 1200 Watts Mixer Grinder Power Plus With 3 Jars (Liquidizing, Wet Grinding And Chutney Jar), Stainless Steel Blades, 1 Year Manufacturer'S Warranty (Black, Llmg210)",
                        //     product_category: "",
                        //     product_image:
                        //       "https://lannister.deals2buy.in/assets/images/IMG_Lifelong_Watts_Mixer_Grinder_20240621_16_54_03_amazon.png",
                        //     product_price: "4499",
                        //     is_purchased: 0,
                        //     wishlist_status: 1,
                        //     list_id: "",
                        //     created_at: "2024-06-21 17:09:06",
                        //     is_favourite: 0,
                        //     is_fulfilled: 0,
                        //     is_mute_notification: 1,
                        //     notification_option_id: 1,
                        //     is_review: 1,
                        //   },
                        //   {
                        //     id: 98,
                        //     user_id: 293,
                        //     uuid_type: "deal",
                        //     uuid: "TKjtnLuVKevj",
                        //     product_url:
                        //       "https://www.amazon.in/dp/B087ZLV39L?tag=deals2buy0b-21",
                        //     product_title:
                        //       "Lifelong 1200 Watts Mixer Grinder Power Plus With 3 Jars (Liquidizing, Wet Grinding And Chutney Jar), Stainless Steel Blades, 1 Year Manufacturer'S Warranty (Black, Llmg210)",
                        //     product_category: "",
                        //     product_image:
                        //       "https://lannister.deals2buy.in/assets/images/IMG_Lifelong_Watts_Mixer_Grinder_20240621_16_54_03_amazon.png",
                        //     product_price: "4499",
                        //     is_purchased: 0,
                        //     wishlist_status: 1,
                        //     list_id: "",
                        //     created_at: "2024-06-21 17:09:06",
                        //     is_favourite: 0,
                        //     is_fulfilled: 0,
                        //     is_mute_notification: 1,
                        //     notification_option_id: 1,
                        //     is_review: 1,
                        //   },
                        // ]}
                        productsList= {similarItemsData.similar_deals}
                        editPen={"none"}
                        setSelectList={setSelectList}
                        Headding={"Add To List"}
                        btnText1={"Confirm"}
                        addToList={addToList}
                        fetchListData={fetchListData}
                        threeDotsEvent={threeDotsEvent}
                        similarProduct={similarItems}
                      />
                    </>
                  ) : (
                    <>
                      {priceEvolution ? (
                        <EditList
                        domain={domain}
                          productsList={productData}
                          editPen={"none"}
                          setSelectList={setSelectList}
                          Headding={"Price Evolution"}
                          btnText1={"Confirm"}
                          addToListFunction={addToListFunction}
                          threeDotsEvent={threeDotsEvent}
                          priceEvolution={priceEvolution}
                          priceEvolutionData={priceEvolutionData}
                        />
                      ) :
               <>
                     { isReviews ?
                             <EditList
                             domain={domain}
                             productsList={productReviewsData}
                             editPen={"none"}
                             setSelectList={setSelectList}
                             Headding={"Reviews"}
                             btnText1={"Confirm"}
                             threeDotsEvent={threeDotsEvent}
                             isReviews={isReviews}

                           />
                      :
                      (
                        <EditList
                        domain={domain}
                          listData={
                            notifyWhen
                              ? editSettingsData.notification_options
                              : fetchListData.data
                          }
                          productsList={productData}
                          editPen={"none"}
                          setSelectList={setSelectList}
                          Headding={notifyWhen ? "Notify When" : "Add To List"}
                          btnText1={"Confirm"}
                          btnText2={!addLists ? undefined : "create New List"}
                          addToListFunction={addToListFunction}
                          threeDotsEvent={threeDotsEvent}
                          gap={"33%"}
                          height={
                            !addLists
                              ? !notifyWhen
                                ? "486px"
                                : "auto"
                              : "auto"
                          }
                          listHeight={
                            !addLists
                              ? !notifyWhen
                                ? "300px"
                                : "auto"
                              : "auto"
                          }
                          setEditSetting={setEditSetting}
                          onConfirmation={onConfirmation}
                          lastChangesSaving={lastChangesSaving}
                      marginBottom={'20px'}

                        />
                      )}
               </>
                      
                      }
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProductUpdateComponent;
