import React, { useEffect, useState } from "react";
import { useActiveTab } from "./ActiveTabConntext";
import { Link } from 'react-router-dom';
import useGetDeals from "./ApisCallsComponent";
import { useGetNotification } from "./ThreeDotsApiCallsHook";


const HeaderComponent = ({activeTab,closeSlider,notificationTab,domain})=>{
    // const { activeTab } = useActiveTab();
    // const { navigate } = useActiveTab();
// getNotifications
const [totalPoints,setTotalPoints] = useState(0)
const [notificationCount,setNotificationCount] = useState(0)
const { getNotificationsData, NotificationsDataFromDerver } = useGetNotification();

useEffect(() => {
  console.log( getNotificationsData)

 if(getNotificationsData != undefined  ){
  setTotalPoints(getNotificationsData.total_points)
  setTotalPoints( getNotificationsData.unread_count)
  // setTotalPoints(500)

  try {
       // Handle the response and send a postMessage
       const parentWindow = window.parent;
       // parentWindow.postMessage({ type: 'NOTIFICATION_RESPONSE', payload: response.data }, '*');
       console.log('unread_count ', getNotificationsData.unread_count)
    parentWindow.postMessage({ action: 'notificationCount',notificationCount: getNotificationsData.unread_count }, domain);
   
  } catch (error)   {
    console.log('Error fetching notifications:', error);
    // Optionally, you can send a postMessage for errors as well
    // parentWindow.postMessage({ type: 'NOTIFICATION_ERROR', payload: error }, '*');
  }
 }
}, [getNotificationsData]);
 

// Function to repeatedly call getNotificationsData every 5 minutes
const startNotificationPolling = () => {
  // Initial call
  NotificationsDataFromDerver();
  
  // Set interval to call the function every 5 minutes
  setInterval(() => {
    NotificationsDataFromDerver();
  }, 300000); // 300000 milliseconds = 5 minutes
};

useEffect(()=>{startNotificationPolling();},[])
    return(<>
    <div className="header">

        <h3>
        {/* <Link to={navigate}> 
    <i
              class="fa fa-arrow-left"
              aria-hidden="true"
              style={{
                fontSize: "25px",
                float: "right",
                position: "relative",
                top: "-21px",
                right: "0",
                fontSize: "20px",
                fontWeight: "200",
                cursor: "pointer",
                display:'none'
              }}
            ></i>
    </Link> */}
        {activeTab}
        </h3>
      
        <ul className='headerItem' >
    <li >
    {/* <img src="saved-icon.png" alt="Img1" class="icon"></img> */}
    <i class="fa fa-get-pocket" style={{ transform:'rotate(90deg)',color: 'white',fontSize:'25px'}} ></i>
    <span>{totalPoints}</span>
    </li>
    <li >
    {/* <img src="saved-icon.png" alt="Img2" class="icon"></img> */}
   
    <a href="https://lannister.deals2buy.in/" target="_blank" rel="noopener noreferrer" style={{}}> <i class="fa fa-smile-o" aria-hidden="true" style={{fontSize:'25px',color: '#fff'}} ></i></a>

    </li> 
       <li  onClick={()=>{notificationTab()}}>
    {/* <img src="saved-icon.png" alt="Img3" class="icon"></img> */}
    <div style={{
      position: 'absolute',
      height: '17px',
      width: 'auto',
      borderRadius: '10px',
      display: 'flex',
      transform: 'translate(12px, 14px)',
      color: 'orange',
      alignItems: 'center',
      fontSize: '11px',
      background: 'white',
      fontWeight: '600',
      padding: '4px',
    }}>{notificationCount}</div>
    <i class="fa fa-bell" aria-hidden="true" style={{fontSize:'25px',}} ></i>
    </li>
    <li onClick={()=>{closeSlider()}}  >
    {/* <img src="saved-icon.png" alt="Img3" class="icon"></img> */}
    
    <i class="fa fa-close" aria-hidden="true"style={{fontSize:'25px',color:'#fff'}} ></i>
    </li>
    </ul>
        
    </div>
    </>)
}

export default HeaderComponent;