import "./Shopping.css";
import "./CardItem";
import {
  HotDeals,
  SimilarDeals,
  TrendingDeals,
  RecentlyViewdDeals,
  AboutStore,
  ShopAt,
  ShippingInfo,
} from "./CardItem";

const Yourshopping = ({ titlePayload, domain }) => {
  return (
    <div className="shopping_wrapper">
     
        <AboutStore />
  
      {/* <section id="shipping_section"> */}
        <ShippingInfo />
      {/* </section> */}
   
      {/* <section className="deals_section"> */}
        <HotDeals domain={domain} />
      {/* </section> */}
      {/* <section className="trending_section"> */}
        <TrendingDeals domain={domain} />
      {/* </section> */}

      {/* <section > */}
        <ShopAt domain={domain} />
      {/* </section> */}
    </div>
  );
};

export default Yourshopping;
