import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateList,fetchList } from "./actions";

const CreateNewList = ({ updateProductList, closeCreatList,height ,top}) => {
  const [listName, setListName] = useState('');
  const [listDesc, setListDesc] = useState('');
  const [listId, setListId] = useState('');
  const [saveClicked, setSavedClicked] = useState(false);

  const dispatch = useDispatch();

  // Get store list data
  const createListData = useSelector((state) => state.createListData);
  const createListError = useSelector((state) => state.createListError);
  const loadingCreateList = useSelector((state) => state.loadingCreateList);

  const addUpdateData = useSelector((state) => state.addUpdateData);
  useEffect(()=>{
    console.log('addUpdateData ',addUpdateData)
    if( saveClicked && listName != '' && addUpdateData &&  addUpdateData.message != undefined && addUpdateData.message.includes('successfully')){
      dispatch(fetchList({}));
      setListName('');
      setListDesc('');
      closeCreatList();
    }
  },[addUpdateData])
  const saveNewList = (e) => {
    // e.preventDefault();
    setSavedClicked(true)

    // Prepare object to update list
    const newList = {
      list_id: listId,
      list_name: listName,
      list_desc: listDesc
    };

    // Update product list and dispatch action
    if (listName.trim() !== '') {
      updateProductList(newList);
      dispatch(addUpdateList(newList));
    }

    // Reset the form


  };

  const createNewListDes = (e) => {
    setListDesc(e.target.value);
  };

  const createNewList = (e) => {
    setListName(e.target.value);
  };

  const closeCreatListBox = () => {
    console.log('create close')
    setListDesc('');
    setListName('');
    closeCreatList();
  };

  return (
    <>
      <div className="filterOverlay" style={{ display: "flex",height:'100vh' }}>
        <div className='addListForm' style={{height : height ? height :'551px'}}>
          <div style={{ display: 'flex', gap: '26%' }}>
            <div onClick={closeCreatListBox}>
              <i className="fa-solid fa-arrow-left" style={{ marginTop: '5px' }}></i>
            </div>
            <h3>Create New List</h3>
          </div>

          <form  >
            <input type="text" name="listName" id="listName" placeholder='List Name' value={listName} onChange={createNewList} maxLength={20} />
           {   listName != ''  && addUpdateData &&  addUpdateData.message.includes('already') && addUpdateData.message.includes(listName)  ?<label className="updateListMessage" style={{top:top?top:'151px'}}>{addUpdateData.message}</label>:''}
            <textarea name="listDescription" id="listDescription" placeholder='List Description (optional)' value={listDesc} onChange={createNewListDes} style={{ height: '200px', resize: 'none' }} maxLength={100} />
            <button type="button" className='createNewList' onClick={saveNewList}>Save changes</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateNewList;
