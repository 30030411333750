import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoActiveDeals from './noActiveDeals';
import CouponsList from './CouponsList';
import { fetchFavStores, fetchFavStoreCoupons } from './actions';
import Slider from './reusableSlider';

const FavoriteStores = ({ favStoresList, isCheckoutMode, domain, setCouponData, setstoreName, handleSeeAllClick, seeAll, copyToClipboard }) => {
  const [activeStore, setActiveStore] = useState(null);
  const [expandDiv, setExpandDiv] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [couponPayloadReq, setCouponPayloadReq] = useState({});
  const [favstoreCoupons, setFavStoreCoupons] = useState([]);
  const [storBtnClicked, setStoreBtnClicked] = useState(false);
  const dispatch = useDispatch();
  const fetchFavStoreCouponsData = useSelector((state) => state.fetchFavStoreCouponsData);
  const fetchFavStoreCouponsFailure = useSelector((state) => state.fetchFavStoreCouponsFailure);
  const loadingfetchFavStoreCoupons = useSelector((state) => state.loadingfetchFavStoreCoupons);

  const handleStoreClick = (index, item) => {
    setActiveStore(index);
    getFavStoreCoupons(index, item);
  };

  const getFavStoreCoupons = (index, store) => {
    setstoreName(store.store_name);
    setActiveStore(index);
    setStoreName(store.store_name);
    dispatch(fetchFavStoreCoupons({ store_id: store.store_id }));
    setStoreBtnClicked(true);
  };

  useEffect(() => {
    if (favStoresList && favStoresList.length > 0 && activeStore === null) {
      handleStoreClick(0, favStoresList[0]);
    }
  }, [favStoresList]);

  useEffect(() => {
    if (storBtnClicked) {
      setFavStoreCoupons(fetchFavStoreCouponsData);
    }
  }, [storBtnClicked]);

  useEffect(() => {
    setFavStoreCoupons(fetchFavStoreCouponsData);
  }, [fetchFavStoreCouponsData]);

  return (
    <>
      {favStoresList && favStoresList.length > 0 ? (
        <>
          <ul className='favStoreList'>
            <Slider
            scrollPercent={100}
            widthOfItem={100}
              items={favStoresList}
              title=''
              height={'auto'}
              gap={'10px'}
              position={'absolute'}
              width={'-webkit-fill-available'}
              arrowPosition={'relative'}
              arrowLeft={'0%'}
              arrowTop={'64%'}
              arrowbackground={'#f3f4f6'}
              arrowheight={'100px'}
              renderItem={(item, index) => (
                <li
                  key={index}
                  onClick={() => handleStoreClick(index, item)}
                  className={activeStore === index ? 'favStore activeStore' : 'favStore'}
                  style={{
                    backgroundColor: activeStore === index ? 'rgba(254, 138, 67, 1)' : 'transparent',
                  }}
                >
                  <img src={''} alt='' className='toggleDiv' />
                  {item.store_name}
                </li>
              )}
            />
          </ul>

          {isCheckoutMode === 'checkout' ? (
            <>
              {favstoreCoupons && favstoreCoupons.store_coupons && favstoreCoupons.store_coupons.length ? (
                <div className='expandableDiv'>
                  <div className='expandCoupons'>
                    <div style={{ textAlign: 'start' }}>
                      <h3> coupons found</h3>
                      <small>Will be automatically applied at checkout</small>
                    </div>
                    <div
                      onClick={() => {
                        setExpandDiv(!expandDiv);
                      }}
                      className={expandDiv ? 'expandDownArrow expandUpArrow' : 'expandDownArrow'}
                    ></div>
                  </div>
                  {expandDiv ? (
                    <>
                      <CouponsList
                        coupons={favstoreCoupons.store_coupons}
                        domain={domain}
                        setCouponData={setCouponData}
                        storeName={storeName}
                        handleSeeAllClick={handleSeeAllClick}
                        seeAll={true}
                        copyToClipboard={copyToClipboard}
                        couponsColor={'#F3F4F6'}
                      />
                    </>
                  ) : null}
                </div>
              ) : (
                <NoActiveDeals />
              )}
            </>
          ) : (
            <CouponsList
              coupons={favstoreCoupons.store_coupons}
              domain={domain}
              isCheckoutMode={isCheckoutMode}
              setCouponData={setCouponData}
              storeName={storeName}
              handleSeeAllClick={handleSeeAllClick}
              seeAll={true}
              copyToClipboard={copyToClipboard}
            />
          )}
        </>
      ) : (
        <NoActiveDeals title='favList' />
      )}
    </>
  );
};

export default FavoriteStores;
