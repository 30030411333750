import React, { useEffect, useState } from 'react';
import CreateNewList from './createNewList';
import { useDispatch, useSelector } from 'react-redux';
import { fetchList } from './actions';

const ProductListContainer = ({ saveList, onListSelect, openCreatNewListBox, title,disabled ,createdList,setEditBox,section}) => {

  console.log('disabled ',disabled)
  const [savelistBox, setSaveListBox] = useState(false);
  const [list, setList] = useState(saveList);
  const [suggestedList, setsuggestedList] = useState(saveList);
  const [backgroundColor, setBackgroundColor] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  console.log('saveList ',list)

  const colors = [
    'rgba(0, 123, 255, 0.8)', // Light blue
    '#9D4EDD', // Light indigo
    'rgba(232, 62, 140, 0.8)', // Light pink
    'rgba(255, 193, 7, 0.8)', // Light yellow
    'rgba(40, 167, 69, 0.8)', // Light green
    'rgba(32, 201, 151, 0.8)', // Light teal
    'rgba(23, 162, 184, 0.8)' // Light cyan
  ];

  const dispatch = useDispatch();

  const fetchListData = useSelector((state) => state.fetchListData);
  const fetchListError = useSelector((state) => state.fetchListError);
  const loadingfetchList = useSelector((state) => state.loadingfetchList);

  useEffect(() => {
    dispatch(fetchList({}));
  }, [dispatch]);

  useEffect(() => {
   setList(saveList)
  }, [saveList]);

  useEffect(() => {
    // Load selected lists from local storage when the component mounts
    let savedSelectedLists=[];
    console.log('section ',section)
    if(section == 'saved'){
      savedSelectedLists = localStorage.getItem('savedSelectedLists') ? JSON.parse(localStorage.getItem('savedSelectedLists')) : [];
      setSelectedLists(savedSelectedLists);

     
    }else{
      savedSelectedLists = localStorage.getItem('selectedLists') ? JSON.parse(localStorage.getItem('selectedLists')) : [];
      setSelectedLists(savedSelectedLists);
    }
   

    // Set background colors based on saved selected lists and fetched data
    if (fetchListData && fetchListData.data) {
      const allLists = fetchListData.data;
      const newBackgroundColors = allLists.map((list) =>
        savedSelectedLists.includes(list.list_id)
      );
      setBackgroundColor(newBackgroundColors);

      let myList = allLists.filter((list) => list.created_by != null);
      setList(myList);
      if(createdList != undefined)createdList(myList)
      let suggestedListData = allLists.filter((list) => list.created_by == null);
      setsuggestedList(suggestedListData);
    }
  }, [fetchListData]);

  const toggleSaveItem = (listId) => {
    // onListSelect(selectedLists.join(','));
    // Retrieve the saved lists from localStorage or initialize an empty array if not present
    let savedSelectedLists ;
    if(section == 'saved'){
      savedSelectedLists = localStorage.getItem('savedSelectedLists') ? JSON.parse(localStorage.getItem('savedSelectedLists')) : [];
      setSelectedLists(savedSelectedLists);

     
    }else{
      savedSelectedLists = localStorage.getItem('selectedLists') ? JSON.parse(localStorage.getItem('selectedLists')) : [];
      setSelectedLists(savedSelectedLists);
    }
    // Check if the listId is already in the saved lists
    const isSelected = savedSelectedLists.includes(listId);

    if (!isSelected) {
      // If not selected, add it to the saved lists
      savedSelectedLists.push(listId);
    } else {
      // If selected, remove it from the saved lists
      savedSelectedLists = savedSelectedLists.filter((id) => id !== listId);
    }

    // Save the updated selected lists to localStorage
    // localStorage.setItem('savedSelectedLists', JSON.stringify(savedSelectedLists));

    // Update setSelectedLists state
    // setSelectedLists(savedSelectedLists);
    if(section == 'saved'){
      localStorage.setItem('savedSelectedLists', JSON.stringify(savedSelectedLists));
      setSelectedLists(savedSelectedLists);

     
    }else{
      localStorage.setItem('selectedLists', JSON.stringify(savedSelectedLists));
      setSelectedLists(savedSelectedLists);
    }

    // Update background colors based on the selected lists
    setBackgroundColor((prevBackgroundColors) => {
      const newBackgroundColors = [...prevBackgroundColors];
      const listIndex = fetchListData.data.findIndex((list) => list.list_id === listId);
      newBackgroundColors[listIndex] = !isSelected; // Toggle the background color based on previous state
      return newBackgroundColors;
    });
  };

 
  useEffect(() => {
 
    let savedSelectedLists=[];
    console.log('section ',section)
    if(section == 'saved'){
      savedSelectedLists = localStorage.getItem('savedSelectedLists') ? JSON.parse(localStorage.getItem('savedSelectedLists')) : [];
      setSelectedLists(savedSelectedLists);

     
    }else{
      savedSelectedLists = localStorage.getItem('selectedLists') ? JSON.parse(localStorage.getItem('selectedLists')) : [];
      setSelectedLists(savedSelectedLists);
    }
  }, []);



  const addNewList = () => {
    setSaveListBox(!savelistBox);
    openCreatNewListBox();
  };

  const toggleListBtn = (id) => {
    toggleSaveItem(id);
  };

  // notification section 


  const addNewNotification = () => {
    // Implementation for adding a new notification
};

const [selectedNotifications,setSelectedNotifications] = useState(); // Replace with your actual state for selected notifications
useEffect(() => {
  const notificationId =  localStorage.getItem("notificationId") ?JSON.parse(localStorage.getItem("notificationId")):'';
  setSelectedNotifications(notificationId)
}, [selectedLists, onListSelect]);
const toggleNotification = (notification_id) => {
    // Implementation for toggling a notification
    setSelectedNotifications(notification_id)
    localStorage.setItem("notificationId",JSON.stringify(notification_id))
};


// const colors = ["#FFA07A", "#20B2AA", "#87CEFA"]; // Replace with your actual color array


  return (
    <>
   { setEditBox !=undefined   ?
             <h3>Add List        
          {list && list.length >0 && title === 'suggestedList'  ||  title=="allList"  ?       <span
      onClick={() => {
        // EditList(list);
        setEditBox(true);
      }}
    >
   <i
        className="fa-solid fa-pen"
        style={{
          marginLeft: "5px",
          fontSize: "13px",
          cursor:'pointer',
          // display: list && list.length > 0 ? 'block' : "none",
        }}
      ></i>
    </span>:<></>}</h3> 
    :"" }
      <ul className='listBtns'>
        {title === 'suggestedList'  ||  title=="allList"?
          <>
            <li onClick={addNewList} className="addNewBtn" style={{ color: 'black' }}>+ Add New</li>
            {fetchListData.data !== undefined && fetchListData.data.map((list, index) => (
              <li
                key={list.list_id}
                onClick={() => { toggleListBtn(list.list_id) }}
                className="listItem"
                style={{
                  color: selectedLists.includes(list.list_id) ? 'white' : '',
                  background: selectedLists.includes(list.list_id) ? 'rgb(254, 138, 67, 1)' : colors[index % colors.length],
                  transition: 'background-color 0.3s ease',
                }}
              >
                {list.list_name}
              </li>
            ))}
          </>
          :
          <>
            {title === 'notificationOptions' ?
        <>
    
        {saveList.notification_options !== undefined && saveList.notification_options.map((notification, index) => (
          <li
            key={notification.notification_id}
            onClick={() => { toggleNotification(notification.notification_id) }}
            className="notificationItem"
            style={{
              color: disabled == false  ? selectedNotifications == notification.notification_id ?' white' : 'black' : 'gray',
              background: disabled == false ? selectedNotifications == notification.notification_id   ?'rgb(254, 138, 67, 1)' :'transparent' : 'transparent',
              transition: 'background-color 0.3s ease',
              pointerEvents:disabled == true ? 'none':'auto',
              border:  disabled == false ? selectedNotifications == notification.notification_id   ?' white' :   '1px solid #cec6c6':' 1px solid #cec6c6'
            }}
          >
            {notification.notification_title}
          </li>
        ))}
      </>
      
              :
              < >
                {suggestedList !== undefined && suggestedList.length > 0 && suggestedList.map((list, index) => (
                  <li
                    key={list.list_id}
                    onClick={() => { toggleListBtn(list.list_id) }}
                    className="listItem"
                    style={{
                      color: selectedLists.includes(list.list_id) ? 'white' : '',
                      background: selectedLists.includes(list.list_id) && disabled == false ? 'rgb(254, 138, 67, 1)'  : colors[index % colors.length],
                      transition: 'background-color 0.3s ease',
                      pointerEvents:disabled == true ? 'none':'auto',
                      
                    }}
                  >
                    {list.list_name}
                  </li>
                ))}
              </>

            }
          </>
        }
      </ul>
    </>
  );
};

export default ProductListContainer;
