// useToGetFavStores.js

import { useEffect,useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductList} from './actions';


const useGetDeals = () => {
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);
 
  const getDealsData = useCallback(() => {
    dispatch(fetchProductList(''))

  }, [dispatch]);

  console.log(productList)
  return{ productList,getDealsData};
};

export default useGetDeals;
