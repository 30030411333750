import "./Shopping.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductList, fetchSimilarProduct, fetchList } from "./actions";
import watchImg from "../assets/img2.jpg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SearchInput from "./SearchInput";
import { current } from "@reduxjs/toolkit";
import CreateNewList from "./createNewList";
import { buildUrl, getStoreName, handleRedirect } from "./BuildUrl";
import useUniqueId from "./useUniqueIdHook";
import useGetWishList from "./getWishListHook";
import WishListCard from "./wishListCard";
import RecentlyWivewed from "./recentlyViewed";
import EditList from "./EditListComponent";
import ProductUpdateComponent from "./productUpdateComponent";
import ProductListContainer from "./productListContainer";
import { fetchWishList} from './actions';
import {
  useSaveToWishList,
  useAddToList,
  useUpdateNotification,
} from "./SaveWishListHook";

const SavedComponent = ({
  savedProductData,
  domain,
  filterOption,
  listAndFilter,
  filterByLists,
  Heading,
}) => {
  const [filteredProducts, setFilteredProducts] = useState(savedProductData);
  const [recentData, setRecentData] = useState([]);
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [openEditBox, setopenEditBox] = useState(false);
  const [CreatNewListBox, setCreatNewListBox] = useState(false);
  const boxRef = useRef(null);
  const [listFilterBox, setListFilterBox] = useState(false);
  const [productsList, setProductsList] = useState([
    { name: "Favourates", desc: "" },
    { name: "Fullfilled", desc: "" },
  ]);
  const dispatch = useDispatch();
  const [listData, setListDataa] = useState();
  const [isProcessed, setIsProcessed] = useState(false);

  const fetchListData = useSelector((state) => state.fetchListData);
  const fetchListError = useSelector((state) => state.fetchListError);
  const loadingfetchList = useSelector((state) => state.loadingfetchList);

  useEffect(() => {
    dispatch(fetchList({}));
  }, [dispatch]);

  const addUpdateData = useSelector((state) => state.addUpdateData);
  useEffect(()=>{
    console.log('addUpdateData ',addUpdateData)
    if(addUpdateData ){
      dispatch(fetchList({}));
    }
  },[addUpdateData])

  const [recentDataUpdated,setRecentDataUpdated] = useState(false)

  useEffect(() => {
    if(savedProductData.length>0){
        setFilteredProducts(savedProductData) ;
    
        console.log('recentDataUpdated ',savedProductData)
        console.log('recentDataUpdated ',recentDataUpdated)
        if( recentDataUpdated == false ){
          setRecentDataUpdated(true);
        //   updateRecentDataFunction(savedProductData);
            handleFilterFunctions();
        //     setIsProcessed(false)
           
           }
       }
  }, [savedProductData]);

  // useEffect(()=>{if(isProcessed == true )  handleFilterFunctions();},[isProcessed])



  useEffect(() => {
    console.log(fetchListData.data);
    if(fetchListData.data != undefined &&  fetchListData.data.length > 0)
    setListDataa(fetchListData.data.filter(list => list.created_by != null));
  }, [fetchListData]);
  const [lastSavedProduct, setLastSavedProduct] = useState(() => {
    return filterOption == undefined
      ? ["Last Saved", "High To Low", "Low To High"]
      : ["Last Viewed", "High To Low", "Low To High"];
  });
  const [daysFilterBox, setDaysFilterBox] = useState(false);
  const [filterHeading, setfilterHeading] = useState(filterOption);
  const [searchText, setSearchText] = useState("");

  const { wishListData, loadingWishList, handleGetToWishList, wishListError } =
    useGetWishList();
  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState("");
  // const platform =getStoreName(domain)

  const [wishList, setWishList] = useState([]);
  const [wishlistDataUpdated,setwishlistDataUpdated] = useState(false)
  useEffect(()=>{
    console.log('wishlistDataUpdated ',wishlistDataUpdated)
    if( Heading == undefined &&  wishlistDataUpdated == false &&  wishListData != undefined &&  wishListData.data != undefined && wishListData.data.length > 0  ){
      setwishlistDataUpdated(true)
      handleFilterFunctions()
    }
  },[wishListData])

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const redirecToProductPage = (url, platform, storeId) => {
    // const dealUrl = buildUrl(uuid, url, platform,storeId);
    console.log("catchurl ", url);
    handleRedirect(url, storeId, true, domain);
  };

  useEffect(() => {
    console.log("Heading ", Heading);
    if (Heading == undefined) {
      handleGetToWishList();
    }
  }, [Heading]);



  console.log(domain);
  const [showCurrentWebsite, setShowCurrentWebsite] = useState(false);
  const [showOtherWebsites, setShowOtherWebsites] = useState(false);
 
  useEffect(() => {
    console.log(domain);
    let currentWebsite = JSON.parse(localStorage.getItem("currentWebsite"));
    console.log("currentWebsite ", currentWebsite  == true);
  
    if (currentWebsite  == true ) {
      setShowCurrentWebsite(currentWebsite);
    
  
  }else{
    setShowCurrentWebsite(false);
  }
  }, [showCurrentWebsite,domain]);


  useEffect(() => {
    console.log(domain);
    let otherWebsites = JSON.parse(localStorage.getItem("otherWebsites"));
   
    if (otherWebsites == true) {
      setShowOtherWebsites(otherWebsites);
 
  }else{
    setShowOtherWebsites(false);
  }
  }, [ showOtherWebsites,domain]);

  // start


  const handleFilterFunctions = async (lastSaved) => {
      setIsProcessed(false);
      //  setRecentDataUpdated(false);
           if (wishList.length === 0) {
      setWishList(wishListData.data);
    }
  
    setOpenFilterBox(false);
    console.log(wishList);
  
    try {
      let filteredData = Heading == undefined ? wishListData.data : savedProductData;
  
      // Search with text
      console.log('search text ',searchText != '')
      if (searchText !== '') {
        filteredData = await performSearch( searchText,filteredData);
      }
  
      if(lastSaved != undefined )localStorage.setItem('type',JSON.stringify(lastSaved))
      // Last save function
      let type = filterHeading === undefined  
        ? localStorage.getItem('type') 
          ? JSON.parse(localStorage.getItem('type')) 
          : 'Last Saved' 
        : localStorage.getItem('type') 
          ? JSON.parse(localStorage.getItem('type')) 
          : filterHeading;
  
      console.log('combine ', filteredData);
  
      // Filter by list
      filteredData = await filterByList(type,filteredData);
      console.log('combine ', filteredData);
  
      // Filter website with text
      filteredData = await filterWebsitewithText(filteredData);
      console.log('combine ', filteredData);
  
      // Days product filter
      filteredData = await daysProductFilter(filteredData);
      console.log('combine ', filteredData);
  
      // List filtering
      filteredData =  await getFilteredProducts(filteredData);
      console.log('combine ', filteredData);

      
      Heading == undefined ? setWishList(filteredData) : setFilteredProducts(filteredData);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  
  // Modified functions to accept and return data
  
  const daysProductFilter = (data) => {
    return new Promise((resolve, reject) => {
      try {
        setIsProcessed(false);
        console.log('filter with days -------------', data);
        let daysOption = localStorage.getItem("daysOption");
        if (daysOption) {
          setSelectedOption(daysOption);
          data = filterProducts(data, daysOption);
        } else {
          setSelectedOption("");
          data = filterProducts(data, "");
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const getStartDateRange = (option) => {
    const now = new Date();
    switch (option) {
      case "today":
        return {
          start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
          end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)
        };
      case "lastday":
        const startLastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        const endLastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
        return { start: startLastDay, end: endLastDay };
      case "last7days":
        const startLast7Days = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0, 0);
        return { start: startLast7Days, end: now };
      case "last30days":
        const startLast30Days = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        return { start: startLast30Days, end: now };
      default:
        return null;
    }
  };
  
  const filterProducts = (data, option) => {
    const dateRange = getStartDateRange(option);
    console.log("Date Range:", dateRange);
    if (!dateRange) return data;
  
    let filtered = data.filter((product) => {
      const productDate = new Date(product.created_at);
      console.log("Product Date:", productDate);
      return productDate >= dateRange.start && productDate <= dateRange.end;
    });

    console.log('filtered ',filtered)
    setDaysFilterBox(false);
    return filtered;
  };
  const getFilteredProducts = (data) => {
    return new Promise((resolve, reject) => {
      try {
        setIsProcessed(false);
        if (Heading === undefined) {
          console.log('filter with list -------------', data);
          const filter = localStorage.getItem('savedSelectedLists') ? JSON.parse(localStorage.getItem('savedSelectedLists')) : [];
          const listData = Heading === undefined ? data : savedProductData;
  
          if (filter.length == 0) {
            resolve(listData);
          }
  
          let filtered = listData.filter((product) => {
            if (!product.list_id) return false; // Handle cases where list_id is empty
  
            const productIds = product.list_id.split(",").map(id => parseInt(id.trim()));
            return filter.some(id => productIds.includes(id));
          });
          console.log('filtered ', filter);
          console.log('filtered ', Heading);
          if (Heading === undefined) {
            filtered.length > 0 ? setWishList(filtered) : setWishList([]);
            resolve(filtered);
          } else {
            filtered.length > 0 ? setFilteredProducts(filtered) : setFilteredProducts([]);
            resolve(filtered);
          }
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const filterByList = (type, data) => {
    return new Promise((resolve, reject) => {
      try {
        setIsProcessed(false);
        setfilterHeading(type);
        let sortedProducts;
        console.log(type);
        console.log(data);
        localStorage.setItem('type', JSON.stringify(type));
  
        console.log("isProcessed", isProcessed);
        if (type === "Last Saved" || type === "Last Viewed") {
          setIsProcessed(false);
          sortedProducts = [...data].sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA; // Descending order
          });
        } else if (type === "High To Low") {
          setIsProcessed(false);
          sortedProducts = [...data].sort(
            (a, b) => b.product_price - a.product_price
          );
        } else if (type.trim() === "Low To High") {
          setIsProcessed(false);
          sortedProducts = [...data].sort(
            (a, b) => a.product_price - b.product_price
          );
        } else {
          setIsProcessed(false);
          sortedProducts = data;
        }
        
        console.log("isProcessed", isProcessed);
        console.log("sortedProducts ", sortedProducts);
        Heading === undefined
          ? setWishList(sortedProducts)
          : setFilteredProducts(sortedProducts);
        console.log(filteredProducts);
        setListFilterBox(false);
        resolve(sortedProducts);
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const performSearch = ( searchText,data) => {
    return new Promise((resolve, reject) => {
      try {
        setIsProcessed(false);
        let filtered;
        console.log("Heading type", Heading);
        if (Heading !== undefined) {
          console.log("savedProductData ", savedProductData);
          if (searchText != "" && savedProductData.length > 0) {
            filtered = savedProductData.filter((product) => {
              return (
                product.product_title
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                product.product_url.toLowerCase().includes(searchText.toLowerCase())
              );
            });
          } else {
            filtered = savedProductData;
          }
          filtered.length > 0 ? setFilteredProducts(filtered) : setFilteredProducts([]);;
          resolve(filtered);
          
        } else {
          if (searchText != "" && data.length > 0) {
            filtered = data.filter((product) => {
              return (
                product.product_title
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                product.product_url.toLowerCase().includes(searchText.toLowerCase())
              );
            });
            console.log("filteredList ", filtered);
            filtered.length > 0 ? setWishList(filtered) : setWishList([]);
            resolve(filtered);
          } else {
            // handleGetToWishList();
            resolve(data);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const filterWebsitewithText = (data) => {
    return new Promise((resolve, reject) => {
      try {
        setIsProcessed(false);
        console.log('filter with current and other website -------------', data);
        console.log(domain);
        let domainSite = domain;
        let newFilteredProducts = [];
        let currentWebsite = JSON.parse(localStorage.getItem("currentWebsite"));
        console.log("currentWebsite ", currentWebsite);
        console.log("openFilterBox ", openFilterBox);
  
        if (currentWebsite) {
          setShowCurrentWebsite(currentWebsite);
        }
  
        let otherWebsites = JSON.parse(localStorage.getItem("otherWebsites"));
        if (otherWebsites) {
          setShowOtherWebsites(otherWebsites);
          console.log("otherWebsites ", otherWebsites);
        }
        console.log("showCurrentWebsite ", showCurrentWebsite);
  
        if (currentWebsite === true || otherWebsites === true) {
          let webstieFilterData =data;
          newFilteredProducts = webstieFilterData.filter((product) => {
            const isCurrentWebsite =
              product.product_url.includes(domainSite) ||
              product.product_title.toLowerCase().includes(domainSite.toLowerCase());
            console.log(isCurrentWebsite);
            if (showCurrentWebsite && isCurrentWebsite) {
              return true;
            }
            if (showOtherWebsites && !isCurrentWebsite) {
              return true;
            }
            return false;
          });
          console.log("newFilteredProducts ", newFilteredProducts);
          console.log("Heading ", Heading === undefined);
  
          if (Heading === undefined) {
            newFilteredProducts.length > 0
              ? setWishList(newFilteredProducts)
              : setWishList([]);
              // newFilteredProducts.length == 0 ?  resolve(data):resolve(newFilteredProducts);
              resolve(newFilteredProducts)
          } else {
            newFilteredProducts.length > 0
              ? setFilteredProducts(newFilteredProducts)
              : setFilteredProducts();
              // newFilteredProducts.length == 0 ?  resolve(data):resolve(newFilteredProducts);
              resolve(newFilteredProducts)
          }
          // resolve(data)
        }
        currentWebsite === false && otherWebsites === false ?  resolve(data):resolve(newFilteredProducts);
      } catch (error) {
        reject(error);
      }
    });
  };
  


  // end
//   const filterByList = (type, data) => {
//     setfilterHeading(type);
//  let sortedProducts;
//     console.log(type);
    

//     console.log( "isProcessed",isProcessed);
//     if (type == "Last Saved" || type == "Last Viewed") {
//       setIsProcessed(false)
//       sortedProducts = [...data].sort((a, b) => {
//         const dateA = new Date(a.created_at);
//         const dateB = new Date(b.created_at);
//         return dateB - dateA; // Descending order
//       });
//     } else if (type == "High To Low") {
//       setIsProcessed(false)
//       sortedProducts = [...data].sort(
//         (a, b) => b.product_price - a.product_price
//       );
//     } else if (type.trim() == "Low To High") {
//       setIsProcessed(false)
//       sortedProducts = [...data].sort(
//         (a, b) => a.product_price - b.product_price
//       );
//     } else {
//       setIsProcessed(false)
//       sortedProducts = data;
//     }
//     console.log( "isProcessed",isProcessed);
//     console.log("Heading ", Heading);
//     Heading == undefined
//       ? setWishList(sortedProducts)
//       : updateRecentDataFunction(sortedProducts);
//     console.log(filteredProducts);
//     setListFilterBox(false);
//   };

  // Function to handle search text change
  const [clearText, setClearText] = useState(false);
  const handleSearchChange = (event) => {
    console.log(event.target.value);
    setSearchText(event.target.value);
  };

  const clearSearchText = () => {
    setSearchText("");
    setClearText(true);
    setIsProcessed(false);
  };

  useEffect(() => {
    if (clearText == true) {
      handleFilterFunctions();
      setClearText(false); // Reset clearText to false after running the function
    }
  }, [clearText]);

  // const performSearch = (searchText) => {
  //   setIsProcessed(false)
  //   let filtered;
  //   console.log("Heading type", Heading);
  //   if (Heading != undefined) {
  //     console.log("savedProductData ", savedProductData); 
  //     if (searchText != "" && savedProductData.length > 0) {
  //       filtered = savedProductData.filter((product) => {
  //         return (
  //           product.product_title
  //             .toLowerCase()
  //             .includes(searchText.toLowerCase()) ||
  //           product.product_url.toLowerCase().includes(searchText.toLowerCase())
  //         );
  //       });
  //     } else {
  //       filtered = savedProductData;
  //     }
  //     setFilteredProducts(filtered);
  //   } else {
 
  //       // console.log('wishList.data ',wishList.data)
  //     if (

  //       searchText != "" &&
  //       wishListData.data != undefined &&
  //       wishListData.data.length > 0
  //     ) {
  //       let filteredList = wishListData.data.filter((product) => {
  //         return (
  //           product.product_title
  //             .toLowerCase()
  //             .includes(searchText.toLowerCase()) ||
  //           product.product_url.toLowerCase().includes(searchText.toLowerCase())
  //         );
  //       });
  //       console.log("filteredList ", filteredList);

  //       // if (filteredList.length > 0) wishListData.data = filteredList;
  //       // console.log("wishListData ", wishListData);

  //       filteredList.length > 0
  //         ? setWishList(filteredList)
  //         : setWishList(wishListData);
  //       setWishList(filteredList)
  //     } else {
  //       handleGetToWishList();
  //     }
  //   }
  // };
  useEffect(() => {
    if(searchText != '')
    // performSearch(searchText,wishList);
    handleFilterFunctions()
  }, [searchText]);
  

  // const daysProductFilter = ()=>{
  //   setIsProcessed(false)
  //   console.log('filter with days  -------------')
  //   let daysOption = localStorage.getItem("daysOption");
  //   if (daysOption) {
  //     setSelectedOption(daysOption);
  //     filterProducts( daysOption);
  //   }else{
  //     setSelectedOption("");
  //     filterProducts("");
  //   }
  // }
  const [selectedOption, setSelectedOption] = useState("");
  // useEffect(() => {
  //   daysProductFilter()
  // }, [openFilterBox]);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    localStorage.setItem("daysOption", selectedValue);

    // filterProducts( selectedValue) // Pass the selected value to the parent component or handler
  };
  // const filterProducts = ( option) => {
  //   const products =  Heading == undefined  ? wishListData.data   : savedProductData;
  //   const startDate = getStartDate(products, option);
  //   console.log(startDate);
  //   if (!startDate) return products;

  //   let filtered =  products != undefined && products.filter((product) => {
  //     const productDate = new Date(product.created_at);
  //     console.log(productDate); // Assuming product.date is a string in ISO format
  //     return productDate >= startDate;
  //   });
  //   setDaysFilterBox(false);
  //   Heading == undefined
  //     ? setWishList(filtered)
  //     : updateRecentDataFunction(filtered);
  //   // console.log(filtered);
  // };

  const getStartDate = (option) => {
    const now = new Date();
    switch (option) {
      case "today":
        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
      case "lastday":
        // Setting the start date to the midnight of the previous day
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
      case "last7days":
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0, 0);
      case "last30days":
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
      default:
        return null;
    }
  };
  

  

//   const getFilteredProducts = () => {
//     setIsProcessed(false)
//     if (Heading == undefined) {  console.log('filter with list -------------')
//     const filter = localStorage.getItem('savedSelectedLists') ? JSON.parse(localStorage.getItem('savedSelectedLists')) : [];
//     const listData = Heading == undefined ? wishListData.data : savedProductData;

//     if (filter.length == 0) {
//         return listData;
//     }

//     let filtered = listData != undefined && listData.filter((product) => {
//         if (!product.list_id) return false; // Handle cases where list_id is empty

//         const productIds = product.list_id.split(",").map(id => parseInt(id.trim()));
//         return filter.some(id => productIds.includes(id));
//     });
// console.log('filtered ',filtered)
// console.log('filtered ',Heading)
//     if (Heading === undefined) {
//         filtered.length > 0 ? setWishList(filtered) : setWishList([]);
//     } else {
//         filtered.length > 0 ?  updateRecentDataFunction(filtered) : setFilteredProducts([]);
//     }}
// };

useEffect(() => {

  // if (CouponData && domain) {
    // Add event listener to receive messages from the plugin
    const parentWindow = window.parent;
    console.log(domain)
 
    // const startTime = performance.now();
    parentWindow.postMessage({ action: 'socialMediaShare' }, domain);

}, [ domain]);
 



  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      // setListFilterBox(false);
    }
  };
  useEffect(() => {
    if (listFilterBox) {
      document.addEventListener("mousedown", handleClickOutside);
    } 
    else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listFilterBox]);
  
  const clearFilter = () => {
    localStorage.setItem('savedSelectedLists', JSON.stringify([]));
  localStorage.setItem("daysOption", '');
  localStorage.setItem("currentWebsite", false);
     localStorage.setItem("otherWebsites", false);
    Heading == undefined ? localStorage.setItem("type", JSON.stringify('Last Saved')) :localStorage.setItem("type", JSON.stringify('Last Viewed'));

    Heading == undefined
      ? setWishList(wishListData.data)
      : setFilteredProducts(savedProductData);
    setOpenFilterBox(false);
  };
  useEffect(()=>{
    console.log('Heading ',Heading)
      Heading == undefined    ? localStorage.setItem("type", JSON.stringify('Last Saved')) :localStorage.setItem("type", JSON.stringify('Last Viewed'));
  },[])



  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log("name ", name);
    console.log("checked ", checked);
    if (name == "currentWebsite") {
      setShowCurrentWebsite(checked);
      localStorage.setItem("currentWebsite", checked);
    } else if (name == "otherWebsites") {
      setShowOtherWebsites(checked);
      localStorage.setItem("otherWebsites", checked);
    }

    // filterWebsitewithText()
  };

//   const filterWebsitewithText = ()=>{
//     setIsProcessed(false)
//     console.log('filter with current and other website -------------')
//        console.log(domain);
//        let domainSite = domain
//        let newFilteredProducts =[]
//     let currentWebsite = JSON.parse(localStorage.getItem("currentWebsite"));
//     console.log("currentWebsite ", currentWebsite);
//     console.log("openFilterBox ", openFilterBox);
  
//     if (currentWebsite    ) {
//       setShowCurrentWebsite(currentWebsite);
    
//     }

//     let otherWebsites = JSON.parse(localStorage.getItem("otherWebsites"));
   
//     if (otherWebsites   ) {
//       setShowOtherWebsites(otherWebsites);
//     console.log("otherWebsites ", otherWebsites);
//     }
//     if (currentWebsite == true || otherWebsites == true ) {
//       let webstieFilterData = Heading == undefined  ? wishListData.data : savedProductData
//       const newFilteredProducts =  webstieFilterData &&  webstieFilterData.filter((product) => {
//         const isCurrentWebsite =
//           product.product_url.includes(domainSite) ||
//           product.product_title.toLowerCase().includes(domainSite.toLowerCase());
//         console.log(isCurrentWebsite);
//         if (showCurrentWebsite && isCurrentWebsite) {
//           return true;
//         }
//         if (showOtherWebsites && !isCurrentWebsite) {
//           return true;
//         }
        
      
//         return false;
//       });
//       console.log("newFilteredProducts ", newFilteredProducts);
  

//         if(Heading == undefined){
//           newFilteredProducts !=undefined &&  newFilteredProducts.length > 0
//           ? setWishList(newFilteredProducts)
//           : setWishList([]);
//         }else{
          
//           newFilteredProducts !=undefined &&  newFilteredProducts.length > 0
//           ? updateRecentDataFunction(newFilteredProducts)
//           : setFilteredProducts([]);
//         }
//     }
 
// if(currentWebsite == false && otherWebsites == false){
// setWishList(wishListData.data)
// }


  


//   // other website 

//   // if ( otherWebsites == true) {
//   //   let webstieFilterData = Heading == undefined  ? wishListData.data : savedProductData
//   //   const newFilteredProducts = webstieFilterData &&  webstieFilterData.filter((product) => {
//   //     const isOtherWebsite =
//   //       product.product_url.includes(domainSite) ||
//   //       product.product_title.toLowerCase().includes(domainSite.toLowerCase());
//   //     console.log(isOtherWebsite);
     
//   //     if (showOtherWebsites && !isOtherWebsite) {
//   //       return true;
//   //     }
//   //     return false;
//   //   });
//   //   console.log("newFilteredProducts ", newFilteredProducts);
//   //   newFilteredProducts.length > 0
//   //     ? setWishList(newFilteredProducts)
//   //     : setWishList([]);
//   // }

//   }

  // useEffect(()=>{
  //   filterWebsitewithText()
  // },[openFilterBox,wishListData])

  // update the list in which wil lsaved fav product
  const updateProductList = (listObj) => {
    const updateList = productsList;
    updateList.push(listObj);
    setProductsList(updateList);
    // setOpenFilterBox(true);
    // setCreatNewListBox(false);
  };

  const openCreatNewListBox = () => {
    setOpenFilterBox(false);
    setCreatNewListBox(true);
  };
  const closeCreatListBox = () => {
    setOpenFilterBox(true);
    setCreatNewListBox(false);
  };
  // closeEdit box
  const openEditListBox = () => {
    dispatch(fetchList({}));
    setopenEditBox(true);
    setOpenFilterBox(false);
    

  };
  const closeEditListBox = () => {
    setopenEditBox(false);
    setOpenFilterBox(true);
  };

  // three dot functionality

  const [threeDotsClicked, setThreeDotsClicked] = useState(false);
  const [productData, setProductData] = useState();
  const threeDotsEvent = (data) => {
    console.log(data);
    setProductData(data);
    setThreeDotsClicked(!threeDotsClicked);
  };

  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();
  const { addToListData, addToFavOrFullFilledOrList } = useAddToList();
  const { addUpdateNotificationData, updateNotifcation } =
    useUpdateNotification();

  useEffect(() => {
  // if(threeDotsClicked == true)
    handleSaveToWishList();
  }, [threeDotsClicked]);

  useEffect(() => {
    console.log(savedWishListData);
    if(savedWishListData != undefined &&  savedWishListData.message != undefined && savedWishListData.message.includes('Removed' )) setRecentDataUpdated(false);
  }, [savedWishListData]);

  // addto list function
  const [selectedListIds, setSelectedListIds] = useState([]);
  const addToListIdFunction = (listId, productUrl) => {
    setwishlistDataUpdated(false)
  
    console.log(JSON.parse(localStorage.getItem('selectedLists')).join(','))
    // Call the function with the comma-separated string
    addToFavOrFullFilledOrList({
      product_url: productUrl,
      list_id: JSON.parse(localStorage.getItem('selectedLists')).join(','),
    });
  
    setThreeDotsClicked(false);
  };
  
  const deleteProductData = useSelector((state) => state.deleteProductData);

  useEffect(() => {
    console.log('deleteProductData ',deleteProductData);
    if (deleteProductData.message == "Product deleted successfully") {
      setwishlistDataUpdated(false)
      dispatch(fetchWishList({}));
    }
  }, [deleteProductData, dispatch]);

  // useEffect(() => {
    // if (addToListData) handleGetToWishList();
  // }, [addToListData]);

  
  const setMuteNotifcationFunction = (muteNotification,notificationId, url) => {
    setwishlistDataUpdated(false);
    console.log('wishlistDataUpdated ',wishlistDataUpdated)
    const newMuteNotification = !muteNotification * 1;
    if(typeof(notificationId) == 'number'){
    updateNotifcation({
      product_url: url,
      notification_id: localStorage.getItem("notificationId") ?JSON.parse(localStorage.getItem("notificationId")):'',
      is_mute_notification: newMuteNotification,
      // is_email_notification_enabled:0,
      // is_system_notification_enabled:1,
      type:'SYSTEM'
    });
  }
  else if(typeof(muteNotification) == 'number'){
    console.log('newMuteNotification ',newMuteNotification)
    updateNotifcation({
      product_url: url,
      is_mute_notification: newMuteNotification,
      notification_id: localStorage.getItem("notificationId") ?JSON.parse(localStorage.getItem("notificationId")):'',
      // is_email_notification_enabled:0,
      // is_system_notification_enabled:1,
      type:'SYSTEM'
    });
  }
    setThreeDotsClicked(false);
  };
  

  const [rotateDropdownArrow, setRotateDropdownArrow] = useState('rotate(0deg)');

  const lastSavedToggle = () => {
    if (listFilterBox == false) {
      setListFilterBox(true);
    } else {
      setListFilterBox(false);
    }
  };
  useEffect(()=>{
    if (rotateDropdownArrow == 'rotate(180deg)') {
      setRotateDropdownArrow('rotate(0deg)');
      console.log('rotateDropdownArrow ',rotateDropdownArrow)
    }
    else{
      // setRotateDropdownArrow('rotate(0deg)'); 
    }
  },[listFilterBox])


  // const handleFilterFunctions = () => {
  //   setIsProcessed(false)
  //   setOpenFilterBox(false);
  //   filterWebsitewithText();
  //   daysProductFilter()
  //   // list filtering 
  //   getFilteredProducts();
    

  // }


  //  const [recentDataState, setRecentDataState] = useState(recentData);

 

  const updateRecentDataFunction =(filteredProducts)=>{
   console.log('isProcessed ',isProcessed)
    if (!isProcessed && filteredProducts.length > 0) {
      let updatedRecentData;
  
      console.log('filteredProducts ',filteredProducts)
      if (wishListData?.data?.length > 0) {
        updatedRecentData = filteredProducts.map(recent => {
          const savedMatch = wishListData.data.find(saved => saved.product_url === recent.product_url);
          return savedMatch ? { ...recent, ...savedMatch } : recent;
        });
      } else {
        updatedRecentData = filteredProducts;
      }
  
      console.log('updatedRecentData', updatedRecentData);
      setFilteredProducts(updatedRecentData);
      setIsProcessed(true);
      return updatedRecentData
    }
  }
  // useEffect(() => {
  //   console.log("wishListData ", wishListData);
  //   setWishList(wishListData.data);
  //   if(wishListData != undefined || savedProductData != undefined ){
  //     clearFilter()

  //   }
  // }, [wishListData,savedProductData]);
 
  // useEffect(() => {
 
  //   if(wishListData != undefined  ){
  //     clearFilter()

  //   }
  // }, [wishListData]);


  return (
    <>
      <div className="savedSection">
        {/* <LastSavedComponent performSearch={performSearch}  /> */}
        <>
          <div className="searchBox">
            <SearchInput
              searchText={searchText}
              onSearchChange={handleSearchChange}
              clearSearchText={clearSearchText}
              // onKeyPress={handleKeyPress}
              inputWidth={" -webkit-fill-available"}
              marginIcon={"18px 0px 18px -27px"}
            />

<div className="filterbox">
      <div
        className="filterByLastSaved"
       
      >
        {  filterHeading === undefined 
        ? localStorage.getItem('type') 
          ? JSON.parse(localStorage.getItem('type')) 
          : 'Last Saved' 
        : localStorage.getItem('type') 
          ? JSON.parse(localStorage.getItem('type')) 
          : filterHeading}
        <div
                          onClick={() => {
                            setListFilterBox(!listFilterBox);
                          }}
                          className={
                            listFilterBox
                              ? "expandDownArrow expandUpArrow"
                              : "expandDownArrow"
                          }

                          style={{marginTop:    !listFilterBox
                            ? "-20px"
                            : "-12px",
                            position: 'absolute', 
                            left: '44%',}}
                        ></div>
       
       
      </div>
      {/* <div className="expandableDiv">
                      <div className="expandCoupons">
                        <div style={{ textAlign: "start" }}>
                          <h3> coupons found</h3>
                          <small>
                            Will be automatically applied at checkout
                          </small>
                        </div>
                        <div
                          onClick={() => {
                            setListFilterBox(!listFilterBox);
                          }}
                          className={
                            listFilterBox
                              ? "expandDownArrow expandUpArrow"
                              : "expandDownArrow"
                          }
                        ></div>
                      </div>
       
      </div> */}
      <div
        className="filterByOptions"
        onClick={() => setOpenFilterBox(!openFilterBox)}
      >
        {listAndFilter === undefined ? "List & Filter" : listAndFilter}
      </div>
    </div>
  
          </div>
          <ul
            className="listFilter"
            style={{
              display: listFilterBox ? "flex" : "none",
              width: "206px",
              position: "fixed",
              top: "200px",
              zIndex: "100",
              margin: "0px 10px",
            }}
            ref={boxRef}
          >
            {lastSavedProduct.map((lastSaved, index) => (
              <li
                key={index}
                id={`${lastSaved}-${index}`}
                onClick={() => {
                  // Heading == undefined
                  //   ?
                     handleFilterFunctions(lastSaved)
                    // : filterByList(lastSaved, savedProductData);
                }}
              >
                {lastSaved}
              </li>
            ))}
          </ul>
        </>

        {openFilterBox ? (
          <div
            className="filterOverlay"
            style={{ display: openFilterBox ? "flex" : "none",height:'100vh' }}
          >
            <div
              className="filterByOptionBox"
              style={{ display: openFilterBox ? "flex" : "none" ,height:'566px'}}
            >
              <div class="filterHeaderBox">
                <span class="filterHeader">
                  {listAndFilter == undefined ? "List & Filter" : listAndFilter}
                </span>
                <div
                  onClick={() => {
                    setOpenFilterBox(false);
                  }}
                >
                  <i
                    class="fa fa-close"
                    aria-hidden="true"
                    style={{
                      fontSize: "25px",
                      float: "right",
                      position: "absolute",
                      left: "88%",
                      top: "7px",
                      fontSize: "20px",
                      fontWeight: "200",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
              <div className=" filterSections" >
                {/* <ProductFilter products={ savedProductData} domain={domain}  setFilteredProductslist={setFilterProductsList}/> */}
                <div>
                  <h3 style={{ padding: " 0", margin: "10px 0 10px 0" }}>
                    Filter by
                  </h3>
                  <ul className="checkboxList">
                    <li>
                      <input
                        type="checkbox"
                        name="currentWebsite"
                        checked={showCurrentWebsite}
                        onChange={handleCheckboxChange}
                      />
                      <label> Current Website </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="otherWebsites"
                        checked={showOtherWebsites}
                        onChange={handleCheckboxChange}
                      />
                      <label> Other Websites </label>
                    </li>
                  </ul>
                </div>
                <div>
                  <label htmlFor="dayFilter" className="filterDays">
                    Days :{" "}
                  </label>
                  <select
                    id="dayFilter"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="">Select...</option>
                    <option value="today">Today</option>
                    <option value="lastday">Yesterday</option>
                    <option value="last7days">Last 7 Days</option>
                    <option value="last30days">Last 30 Days</option>
                  </select>
                </div>

                <div>
                  {filterByLists == undefined ? (
                    <div className="listContainer">
                      <h3>
                        {" "}
                        List{" "}
                        {listData != undefined &&  listData.length > 0 ?
                           <span onClick={openEditListBox}>
                           <i
                             class="fa-solid fa-pen"
                             style={{
                               marginLeft: "5px",
                               fontSize: "13px",
                               cursor: "pointer",
                             }}
                           ></i>
                         </span> :
                         <></>}
                     
                      </h3>

                      <ProductListContainer
                        list={productsList}
                        // onListSelect={getFilteredProducts}
                        openCreatNewListBox={openCreatNewListBox}
                        title="allList"
                        section={'saved'}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              
              </div>
              <div className="applyClearFilter" style={{zIndex: '1000',  bottom: '0px',
                    position: 'sticky',
                    width: '-webkit-fill-available'
              }}>
                <button
                  className="applyFilterBtn"
                  onClick={()=>{handleFilterFunctions()}}
                >
                  Apply
                </button>
                <button onClick={clearFilter} className="clearFilter">
                  Clear
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className="filterOverlay"
          style={{ display: CreatNewListBox ? "flex" : "none",height:'100vh' }}
        >
          <CreateNewList
            updateProductList={updateProductList}
            closeCreatList={closeCreatListBox}
            top={'162px'}
          />
        </div>
        <div
          className="filterOverlay"
          style={{ display: openEditBox ? "flex" : "none" ,height:'100vh'}}
        >
          <EditList
            editListData={updateProductList}
            listData={ listData   }
            closeEditListBox={closeEditListBox}
            height={'auto'}
            openEditListBox={openEditListBox}
          />
        </div>

        <div>
          <div className="items_wrapper" style={{display:'contents',padding:'0px'}}>
            {Heading == undefined ? (
              <div>
                <WishListCard
                  data={wishList}
                  loading={loadingWishList}
                  error={wishListError}
                  Heading="Saved Products"
                  getDealsUrl={redirecToProductPage}
                  threeDotsEvent={threeDotsEvent}
                  setProductData={setProductData}
                  addToListIdFunction={addToListIdFunction}
                />
              </div>
            ) : (
              <>
                <WishListCard
                  data={filteredProducts}
                  loading={loadingWishList}
                  error={wishListError}
                  Heading={Heading}
                  getDealsUrl={redirecToProductPage}
                  threeDotsEvent={threeDotsEvent}
                />
              </>
            )}
            {threeDotsClicked ? (
              <ProductUpdateComponent
                setMuteNotifcationFunction={setMuteNotifcationFunction}
                addToListIdFunction={addToListIdFunction}
                closeCreatListBox={closeCreatListBox}
                setThreeDotsClicked={setThreeDotsClicked}
                product={productData}
                threeDotsEvent={threeDotsEvent}
                threeDotsClicked={threeDotsClicked}
                domain={domain}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedComponent;
